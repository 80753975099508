<div class="mat-typography theme-1 login__page-wrapper mat-app-background">
  <div class="mb-5">
    <h1 class="text-center">{{ 'login_app_name' | translate }}</h1>
    <div class="login__options">
      <h4 class="m-0">v{{ version }}</h4>
      <div class="language-selector">
        <button mat-flat-button color="primary" [matMenuTriggerFor]="languageMenu">
          {{ currentLanguage }}
        </button>
        <mat-menu #languageMenu="matMenu">
          <button mat-menu-item *ngFor="let language of languages" (click)="setLanguage(language)">
            {{ language }}
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <!-- <sc-card class="login__card">
    <sc-card-content>
      <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
        <div class="text-danger" [hidden]="!error || isLoading">
          {{ 'login_incorrect_username_or_password' | translate }}
        </div>
        <br />
        <div class="login__form-fields">
          <mat-form-field [hideRequiredMarker]="true">
            <input
              type="text"
              matInput
              formControlName="username"
              autocomplete="username"
              [placeholder]="'login_username' | translate"
              required
            />
            <mat-error *ngIf="loginForm.controls.username.invalid && loginForm.controls.username.touched">
              <span translate>{{ 'login_username_required' | translate }}</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field [hideRequiredMarker]="true">
            <input
              type="password"
              matInput
              formControlName="password"
              autocomplete="current-password"
              [placeholder]="'login_password' | translate"
              required
            />
            <mat-error *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.touched">
              <span>{{ 'login_password_required' | translate }}</span>
            </mat-error>
          </mat-form-field>
          <div class="login__actions">
            <mat-slide-toggle color="primary" formControlName="remember">{{
              'login_remember_me' | translate
            }}</mat-slide-toggle>
            <button mat-flat-button color="primary" type="submit" [disabled]="loginForm.invalid || isLoading">
              <sc-loader class="inline-loader" [isLoading]="isLoading"></sc-loader>
              <span>{{ 'login_login' | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </sc-card-content>
  </sc-card> -->
</div>
