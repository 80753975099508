import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Credentials {
  contact: any;
  email: string;
  id: number;
  is_active: boolean;
  is_password_expired: boolean;
  is_sysadmin: boolean;
  session_token: string;
  username: string;
  theme: 'default' | 'light' | 'dark';
}

export interface RequiredPermission {
  module: string;
  component: string;
  permission: number;
}

const credentialsKey = 'credentials';

export const PermissionTypes = {
  NONE: 0,
  READ: 1,
  WRITE: 2,
  DELETE: 4,
};

export const PermissionModules = {};

export const PermissionComponents = {};

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private _credentials: Credentials = null;
  private _credentials$ = new BehaviorSubject<Credentials>(this._credentials);

  constructor(private cookieService: any) {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + this.token);
      // this.scCoreService.setHttpHeaders(headers);
    } else {
      // let token = this.cookieService.get('session_token');
      let token
      if (token) {
        this._credentials = <any>{};
        this._credentials.session_token = token;
      }
    }
    this._credentials$.next(this._credentials);
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    // return !!this.credentials;
    return true;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials {
    return this._credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get $credentials(): Observable<Credentials> {
    return this._credentials$.asObservable();
  }

  /**
   * Gets the token from the user credentials.
   * @return The token or '' if the user is not authenticated.
   */
  get token(): string {
    return this._credentials ? this._credentials.session_token : '';
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get remember(): boolean {
    return localStorage.getItem(credentialsKey) != undefined;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;
    console.log(credentials);

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + this.token);
      // this.scCoreService.setHttpHeaders(headers);
      if (remember) {
        this.cookieService.set(
          'session_token',
          this._credentials.session_token,
          0,
          '',
          window.location.hostname.substring(window.location.hostname.indexOf('.') + 1)
        );
      }
    } else {
      this.cookieService.deleteAll();
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
      // this.scCoreService.setHttpHeaders(undefined);
    }

    this._credentials$.next(this._credentials);
  }

  checkPermissions(requiredPermissions: RequiredPermission[]) {
    return true;
  }

  checkPermission(currentPermission: number, permission: number) {
    let currentPermissionBin = currentPermission.toString(2);
    let permissionBin = permission.toString(2);
    return currentPermissionBin.charAt(currentPermissionBin.length - permissionBin.length) == permissionBin.charAt(0);
  }

  hasPermission(module: string, component: string, permission: number) {
    return true;
  }
}
