import { Component, OnDestroy } from '@angular/core';

@Component({
  template: '',
})
export class BaseComponent implements OnDestroy {
  public isCalled = false;

  ngOnDestroy() {
    if (!this.isCalled) {
      this.isCalled = true;
      this.ngOnDestroy();
    }
  }

  formInvalid(actions?: any[]) {
    // let toastConfig = new SCToastConfig();
    // toastConfig.color = 'danger';
    // toastConfig.duration = 2000;
    // return this.openToast('invalid_form', toastConfig, actions);
    //this.toastService.open(this.translateService.instant('invalid_form'), undefined, toastConfig)
  }

  openToast(message: string, config: any, actions?: any[]) {
    // return this.toastService.open(message, actions, config);
  }
}
