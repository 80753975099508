import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  //{ path: ':clientId', redirectTo: '/:clientId/dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    data: {
      breadcrumb: {
        label: 'Dashboard',
        parsedLabel: 'Dashboard',
        url: '/:clientId/dashboard',
        parsedUrl: '',
        show: true,
        key: 'mainMenuItem',
      },
    },
    loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'test',
    data: {
      breadcrumb: {
        label: 'Dashboard',
        parsedLabel: 'Dashboard',
        url: '/:clientId/dashboard',
        parsedUrl: '',
        show: true,
        key: 'mainMenuItem',
      },
    },
    loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
