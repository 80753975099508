import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http/http.service';
import { SCData } from '@app/models/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Credentials, CredentialsService } from './credentials.service';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private credentialsService: CredentialsService, private http: HttpService) {
    if (this.credentialsService.credentials) {
      this.getCurrentUser();
      //Hier eine Funktion um get notification aufzurufen
    }
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<SCData<Credentials>> {
    return this.loginHelper(context).pipe(
      map((res) => {
        this.credentialsService.setCredentials(res.data, context.remember);
        return res;
      })
    );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    return of(true);
  }

  loginHelper(context: LoginContext): Observable<SCData<Credentials>> {
    let credentials: Credentials = {
      contact: '',
      email: '',
      id: 1,
      is_active: false,
      is_password_expired: false,
      is_sysadmin: false,
      session_token: '',
      username: '',
      theme: 'default',
    };
    let data: SCData = {
      status: 999,
      data: credentials,
      message: 'OK',
    };
    return of(data);
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  getCurrentUser() {
    return this.getCurrentUserHelper().subscribe((res) => {
      res.data.session_token = this.credentialsService.token;
      this.credentialsService.setCredentials(res.data, this.credentialsService.remember);
    });
  }

  getCurrentUserHelper(): Observable<any> {
    return <Observable<Credentials>>this.http.get('/system/accounts/me');
  }
}
