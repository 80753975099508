import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SCLocalModule } from './sc/sc.module';

@NgModule({
  imports: [MaterialModule, CommonModule, SCLocalModule],
  declarations: [],
  exports: [SCLocalModule],
  providers: [],
})
export class SharedModule {}
