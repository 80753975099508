import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shell',
  template: ` <div class="shell"></div> `,
})
export class ShellComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
